import { useRef, useEffect, useState } from 'react';

function useObserver(options = {}) {
  const [observerEntry, setObserverEntry] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setObserverEntry(entry);
        entry.isIntersecting && setHasEntered(true);
      });
    }, options);

    observer.observe(ref.current);
    return () => observer.unobserve(ref.current);
  }, []);

  return { ref, observerEntry, hasEntered };
}

export default useObserver;
