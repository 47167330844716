import React, { useState } from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import Input from './Input';
import { Caption } from '../styles/Typography';

const AddressInput = ({ label, value, placeholder, setValue, error }) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = async e => {
    setValue(e.target.value);

    const URL = '/.netlify/functions/addressAutocomplete/';

    const response = await fetch(URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ input: e.target.value }),
    });

    const predictions = await response.json();
    setSuggestions(predictions);
  };

  return (
    <Container>
      <Downshift onChange={item => setValue(item)}>
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
        }) => (
          <div>
            <Label>
              <Caption style={error ? { color: '#E45050' } : null}>
                {label}
                {error && ` - ${error}`}
              </Caption>
            </Label>
            <Input
              {...getInputProps({
                type: 'text',
                placeholder: placeholder,
                onChange: e => handleChange(e),
                value,
                style: error ? { border: '1px solid #E45050' } : null,
              })}
            />
            {isOpen && suggestions.length > 1 && (
              <Dropdown>
                {suggestions.map(({ description }, index) => (
                  <Suggestion
                    {...getItemProps({
                      index,
                      key: description,
                      item: description,
                    })}
                    highlighted={highlightedIndex === index}
                  >
                    {description}
                  </Suggestion>
                ))}
              </Dropdown>
            )}
          </div>
        )}
      </Downshift>
    </Container>
  );
};

export default AddressInput;

const Container = styled.div`
  position: relative;
  margin: 0 0 2rem;
  padding: 0;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.label`
  display: block;
  color: inherit;
  margin: 0 0 0.5rem;
  text-align: left;
`;

const Dropdown = styled.div`
  background: white;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 0.5rem;
  z-index: 200;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
`;

const Suggestion = styled.div`
  padding: 1rem;
  text-align: left;
  cursor: pointer;
  /* transition: background 300ms; */
  background: ${({ highlighted }) => highlighted && '#f2e6e4'};
`;
