import React, { useState } from 'react';
import styled from 'styled-components';
import isEqual from 'date-fns/is_equal';
import DatePicker from './DatePicker';

const DateInput = ({ label, value, placeholder, setDate, disabled, error }) => {
  const [hasFocus, setHasFocus] = useState(false);

  const handleDateSelect = ({ selected, selectable, date }) => {
    const datesAreEqual = isEqual(date, value);

    if (datesAreEqual) {
      setDate(null);
    } else {
      setDate(date);
      setHasFocus(false);
    }
  };

  return (
    <Container>
      <Label>
        <p className="caption" style={error ? { color: '#E45050' } : null}>
          {label}
          {error && ` - ${error}`}
        </p>
      </Label>
      <FauxInput
        onClick={() => setHasFocus(true)}
        className={hasFocus && 'focus'}
        style={error ? { border: '1px solid #E45050' } : null}
      >
        {value ? (
          <p>{`${value.toLocaleDateString()}`}</p>
        ) : (
          <p className="placeholder">{placeholder}</p>
        )}
        <Arrow>
          <svg height="24" width="24">
            <polyline points="8,10 12,14 16,10" />
          </svg>
        </Arrow>
      </FauxInput>
      <DatePicker
        selected={value}
        onDateSelected={handleDateSelect}
        active={hasFocus}
      />
      <Overlay
        onClick={() => setHasFocus(false)}
        className={hasFocus && 'active'}
      />
    </Container>
  );
};

export default DateInput;

const Container = styled.div`
  margin: 0 0 2rem;
  padding: 0;
  position: relative;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.label`
  display: block;
  color: inherit;
  margin: 0 0 0.5rem;
  text-align: left;
`;

const FauxInput = styled.div`
  text-align: left;
  font-family: inherit;
  display: block;
  background-color: white;
  color: #6e514c;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid white;
  outline: none;
  margin: 0;
  width: 100%;
  position: relative;

  @media (min-width: 500px) {
    padding: 1rem;
  }

  p {
    font-size: 0.8125rem;
    line-height: 1rem;
    margin: 0;

    &.placeholder {
      color: #6e514c;
      opacity: 0.4;
    }

    @media (min-width: 500px) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  &.focus {
    border: 1px solid #e2b3aa !important;
  }

  &:hover {
    border: 1px solid #ecd9d4;
  }

  &:focus {
    border: 1px solid #e2b3aa;
  }
`;

const Arrow = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: #f8f3f3;
  border-radius: 50%;
  pointer-events: none;
  transition: background 300ms;

  ${FauxInput}:hover &,
  ${FauxInput}.focus & {
    background: #ecd9d4;
  }

  svg {
    display: block;
    fill: none;
    stroke: #6e514c;
    stroke-width: 1.5;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  @media (min-width: 700px) {
    background: transparent;
  }
`;
