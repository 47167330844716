import React from 'react';
import styled from 'styled-components';
import Input from './Input';
import { Caption } from '../styles/Typography';

const TextInput = ({
  label,
  type,
  name,
  defaultValue,
  value,
  placeholder,
  min,
  onChange,
  disabled,
  error,
}) => (
  <Container>
    <Label>
      <Caption style={error ? { color: '#E45050' } : null}>
        {label}
        {error && ` - ${error}`}
      </Caption>
    </Label>
    <Input
      type={type}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      min={min}
      defaultValue={defaultValue}
      disabled={disabled}
      style={error ? { border: '1px solid #E45050' } : null}
    />
  </Container>
);

export default TextInput;

const Container = styled.div`
  margin: 0 0 2rem;
  padding: 0;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.label`
  display: block;
  color: inherit;
  margin: 0 0 0.5rem;
  text-align: left;
`;
