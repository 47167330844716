import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import format from 'date-fns/format';

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import TextInput from '../../components/TextInput';
import AddressInput from '../../components/AddressInput';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import NumberInput from '../../components/NumberInput';
import DateInput from '../../components/DateInput';
import LoadingDots from '../../components/LoadingDots';
import { H1, BigH2, H2, H3, P } from '../../styles/Typography';
import useObserver from '../../hooks/useObserver';

// const todaysDate = new Date().toISOString().slice(0, 10);

const Contact = ({ location }) => {
  const [submitting, setSubmitting] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [occasion, setOccasion] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState('');
  const [notes, setNotes] = useState('');

  const [lisaWillTravel, setLisaWillTravel] = useState(false);
  const [address, setAddress] = useState('');

  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    phone: '',
    occasion: '',
    date: '',
    address: '',
  });

  const validate = () => {
    const errors = {
      name: !name ? 'Please enter your full name' : '',
      email: !email.includes('@') ? 'Please enter a valid email address' : '',
      phone: !phone ? 'Please enter your phone number' : '',
      occasion: !occasion ? 'Please select an occasion' : '',
      date: !date ? 'Please select a date' : '',
      address: lisaWillTravel && !address ? 'Please enter an address' : '',
    };

    setValidationErrors(errors);
    let passes = true;

    Object.values(errors).forEach(value => {
      if (!passes) {
        return;
      }

      if (value) {
        passes = false;
      }
    });

    return passes;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const isValid = validate();

    if (isValid) {
      setSubmitting(true);

      const response = await fetch('/.netlify/functions/booking/', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          occasion,
          numberOfPeople,
          date: format(date, 'YYYY-MM-DD'),
          time,
          lisaWillTravel,
          address,
          notes,
        }),
      });

      const bookingData = await response.json();
      console.log(bookingData);

      if (response.status === 200) {
        navigate('/contact/confirmation', {
          state: bookingData,
        });
      } else {
        setSubmitting(false);
      }
    }
  };

  const settings = { rootMargin: '0px 0px -200px 0px' };
  const { ref, hasEntered } = useObserver(settings);

  return (
    <Layout location={location}>
      <SEO title="Contact" />
      <Container ref={ref} className={!hasEntered ? 'fade-in' : undefined}>
        <Opening>
          <H1>Get in touch</H1>
          <H2 style={{ maxWidth: '19em' }}>
            If you’d like to make a booking with Lisa, fill out the form and she
            will be in touch.
          </H2>
        </Opening>
        <Section>
          <BigH2>Personal details</BigH2>
          <TextInput
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Your full name"
            type="text"
            error={validationErrors.name}
          />
          <TextInput
            label="Email"
            placeholder="Email address"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={validationErrors.email}
          />
          <TextInput
            label="Phone"
            placeholder="Phone number"
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            error={validationErrors.phone}
          />
        </Section>
        <Section>
          <BigH2>About your event</BigH2>
          <Grid21>
            <Select
              label="Occasion"
              value={occasion}
              placeholder="Select occasion"
              onChange={e => setOccasion(e.target.value)}
              options={[
                'Wedding',
                'Formal',
                "Hen's",
                'Special Event',
                'Photoshoot',
                'Other',
              ]}
              error={validationErrors.occasion}
            />
            <NumberInput
              label="No. of people"
              name="quantity"
              value={numberOfPeople}
              updateValue={setNumberOfPeople}
              min={1}
              max={20}
            />
          </Grid21>
          <Grid11>
            <DateInput
              label="Date"
              value={date}
              setDate={setDate}
              placeholder="Select date"
              error={validationErrors.date}
            />
            <Select
              label="Time you need to be ready (optional)"
              placeholder="Select time"
              value={time}
              onChange={e => setTime(e.target.value)}
              options={[
                '9:00 am',
                '10:00 am',
                '11:00 am',
                '12:00 am',
                '1:00 pm',
                '2:00 pm',
                '3:00 pm',
                '4:00 pm',
                '5:00 pm',
                '6:00 pm',
              ]}
            />
          </Grid11>
        </Section>
        <Section>
          <BigH2 style={{ marginBottom: '0.5rem' }}>Location</BigH2>
          <H3 style={{ maxWidth: '27em' }}>
            Appointments can be held at Lisa’s home studio in Wantirna South, or
            at a location of your choosing.
          </H3>
          <Switch>
            <Option
              selected={!lisaWillTravel}
              onClick={() => setLisaWillTravel(false)}
            >
              Lisa's studio
            </Option>
            <Option
              selected={lisaWillTravel}
              onClick={() => setLisaWillTravel(true)}
            >
              Other location
            </Option>
          </Switch>
          {lisaWillTravel && (
            <AddressInput
              label="Address"
              value={address}
              setValue={setAddress}
              placeholder="Address"
              error={validationErrors.address}
            />
          )}
        </Section>
        <Section>
          <BigH2>Additional notes</BigH2>
          <TextArea
            label="Notes (optional)"
            placeholder="Write any extra details"
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </Section>
        <Button type="submit" onClick={handleSubmit} disabled={submitting}>
          {submitting ? <LoadingDots /> : <P>Submit</P>}
        </Button>
      </Container>
    </Layout>
  );
};

export default Contact;

const Container = styled.div`
  max-width: 33rem;
  margin: auto;
  padding: 5rem 1.5rem;
  text-align: center;
  transition: transform 1s, opacity 1s;

  h2,
  h3 {
    margin: 0 auto;
  }
`;

const Opening = styled.div`
  padding: 7.5rem 0;
`;

const Section = styled.div`
  padding: 4rem 0;
  border-top: 1px solid #ebe3e3;
  h2,
  h3 {
    margin-bottom: 2.5rem;
  }
`;

const Button = styled.button`
  background: #e2b3aa;
  color: white;
  padding: 1rem;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  transition: background 200ms;

  &:hover,
  &:focus {
    background: #ebcdc5;
  }
`;

const Grid21 = styled.div`
  & > div {
    margin: 0 0 2rem !important;
  }

  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: 2fr 1fr;
  }
`;

const Grid11 = styled.div`
  & > div {
    margin: 0 0 2rem !important;
  }

  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const Switch = styled.div`
  background: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  margin: 0 0 2rem;
  padding: 0.25rem;

  &:last-child {
    margin: 0;
  }
`;

const Option = styled.button`
  background: ${({ selected }) => (selected ? '#f8f3f3' : 'transparent')};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  border: none;
  outline: none;
  padding: 1rem 0.75rem;
  font-family: inherit;
  color: inherit;
  border-radius: 0.125rem;
  flex: 1;
  cursor: pointer;
  font-size: 0.8125rem;
  line-height: 1rem;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }

  @media (min-width: 500px) {
    padding: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
`;
