import styled from 'styled-components';

const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  font-family: inherit;
  display: block;
  background-color: white;
  color: #6e514c;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid white;
  outline: none;
  margin: 0;
  width: 100%;

  font-size: 0.8125rem;
  line-height: 1rem;

  @media (min-width: 500px) {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  &::placeholder {
    color: #6e514c;
    opacity: 0.4;
  }

  &:disabled {
    opacity: 1;
    color: hsl(0, 0%, 80%);
    background-color: hsl(0, 0%, 97%);
    border: 1px solid hsl(0, 0%, 97%);
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid #ecd9d4;
  }

  &:focus {
    border: 1px solid #e2b3aa;
  }
`;

export default Input;
