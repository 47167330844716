import React from 'react';
import styled from 'styled-components';

const LoadingDots = () => (
  <Container>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </Container>
);

export default LoadingDots;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;

  .dot {
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 50%;
    background: white;
    position: relative;
    margin: 0 0.1875rem;
    animation: bounce 1200ms infinite;

    &:nth-child(1) {
      animation-delay: -360ms;
    }
    &:nth-child(2) {
      animation-delay: -240ms;
    }
    &:nth-child(3) {
      animation-delay: -120ms;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-200%);
    }
    80% {
      transform: translateY(0);
    }
  }
`;
