import React from 'react';
import styled from 'styled-components';
import { Caption } from '../styles/Typography';

const NumberInput = ({
  label,
  name,
  defaultValue,
  value,
  placeholder,
  updateValue,
  disabled,
  min,
  max,
}) => {
  function increment(e) {
    if (value < max) {
      updateValue(value + 1);
    }

    e.target.blur();
  }

  function decrement(e) {
    if (value > min) {
      updateValue(value - 1);
    }

    e.target.blur();
  }

  function handleChange(e) {
    const value = parseInt(e.target.value);

    if (value > max) {
      updateValue(max);
    } else if (value < min) {
      updateValue(min);
    } else {
      updateValue(value);
    }
  }

  return (
    <Container>
      <Label>
        <Caption>{label}</Caption>
      </Label>
      <InputWrapper>
        <Button
          style={{ left: 0 }}
          onClick={decrement}
          disabled={value === min}
        >
          <Icon>
            <svg height="24" width="24">
              <line x1="7" y1="12" x2="17" y2="12" />
            </svg>
          </Icon>
        </Button>

        <Button
          style={{ right: 0 }}
          onClick={increment}
          disabled={value === max}
        >
          <Icon>
            <svg height="24" width="24">
              <line x1="12" y1="7" x2="12" y2="17" />
              <line x1="7" y1="12" x2="17" y2="12" />
            </svg>
          </Icon>
        </Button>

        <Input
          type="number"
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          min={min}
          max={max}
        />
      </InputWrapper>
    </Container>
  );
};

export default NumberInput;

const Container = styled.div`
  margin: 0 0 2rem;
  padding: 0;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.label`
  display: block;
  color: inherit;
  margin: 0 0 0.5rem;
  text-align: left;
`;

const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  display: block;
  text-align: center;
  background-color: white;
  color: #6e514c;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid white;
  outline: none;
  margin: 0;
  width: 100%;

  font-size: 0.8125rem;
  line-height: 1rem;

  @media (min-width: 500px) {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #6e514c;
    opacity: 0.4;
  }

  &:disabled {
    opacity: 1;
    color: hsl(0, 0%, 80%);
    background-color: hsl(0, 0%, 97%);
    border: 1px solid hsl(0, 0%, 97%);
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid #ecd9d4;
  }

  &:focus {
    border: 1px solid #e2b3aa;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  background: transparent;
  margin: 0;
  padding: 1rem;
  border: 1px solid transparent;
  top: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Icon = styled.div`
  background: #f8f3f3;
  border-radius: 50%;
  transition: background 300ms;
  pointer-events: none;

  @media (hover: hover) {
    ${Button}:hover &,
    ${Button}:focus & {
      background: #ecd9d4;
    }
  }

  ${Button}:active & {
    background: #f8f3f3;
  }

  ${Button}:disabled & {
    background: #f8f3f3;
  }

  svg {
    display: block;
    fill: none;
    stroke: #6e514c;
    stroke-width: 1.5;
  }
`;
