import React from 'react';
import styled from 'styled-components';
import { Caption } from '../styles/Typography';

const Select = ({
  label,
  options,
  value,
  onChange,
  disabled,
  placeholder,
  error,
}) => (
  <Container>
    <Label>
      <Caption style={error ? { color: '#E45050' } : null}>
        {label}
        {error && ` - ${error}`}
      </Caption>
    </Label>
    <SelectWrapper>
      <Arrow>
        <svg height="24" width="24">
          <polyline points="8,10 12,14 16,10" />
        </svg>
      </Arrow>
      <StyledSelect
        value={value}
        onChange={onChange}
        disabled={disabled}
        required
        style={error ? { border: '1px solid #E45050' } : null}
      >
        <option value="" hidden disabled>
          {placeholder}
        </option>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  </Container>
);

export default Select;

const Container = styled.div`
  margin: 0 0 2rem;
  padding: 0;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.label`
  display: block;
  color: inherit;
  margin: 0 0 0.5rem;
  text-align: left;
`;

const StyledSelect = styled.select`
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  font-family: inherit;
  padding: 1.25rem 1rem;
  width: 100%;
  background-color: white;
  border: 1px solid white;
  border-radius: 0.25rem;
  color: #6e514c;
  /* cursor: pointer; */

  font-size: 0.8125rem;
  line-height: 1rem;

  @media (min-width: 500px) {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  &:invalid {
    color: hsla(10, 19%, 36%, 0.4);
  }

  &:hover {
    border: 1px solid #ecd9d4;
  }

  &:focus {
    border: 1px solid #e2b3aa;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const Arrow = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: #f8f3f3;
  border-radius: 50%;
  pointer-events: none;
  transition: background 300ms;

  ${SelectWrapper}:hover &,
  ${StyledSelect}:focus & {
    background: #ecd9d4;
  }

  svg {
    display: block;
    fill: none;
    stroke: #6e514c;
    stroke-width: 1.5;
  }
`;
